import Vue from "vue";
import Vuex from "vuex";
import { auth } from "./auth.module";
import form from '@/store/modules/form';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      id: 0,
      firstName: "",
      lastName: "",
      userName: "",
      initials: "",
      email: "",
      roles: [],
      isEnabled: false,
    },
  },
  mutations: {
    setUser(state, authData) {
      state.user = authData;
    },
  },
  actions: {},
  modules: {
    auth,
    form,
  },
});
