<template lang="pug">
v-navigation-drawer.drawer_background(v-model='drawer' app clipped)
  v-list(dense expand)
    v-list-group.drawer_color--text(v-for='item in items' :key='item.title' v-model='item.active' no-action :ripple='false')
      template(v-slot:activator)
        v-list-item-icon.mr-4
          v-icon.drawer_color--text(v-text='item.action')
        v-list-item-content.mr-n4
          v-list-item-title.text-body-2.drawer_color--text(v-text='item.title')
      v-list-item(v-for='child in item.items' :key='child.title' router :to='child.route')
        v-list-item-content.mr-n4
          v-list-item-title.text-body-2.drawer_color--text.font-weight-regular(v-text='child.title')
</template>

<style scoped>
/* Add elevation to the right side of navigation drawer */
nav {
  box-shadow: -10px 0px 10px 5px var(--v-primary-base);
}

/* Make list more compact */
::v-deep .v-list {
  padding: 0;
}

::v-deep .v-list-item {
  min-height: 30px;
}

::v-deep .v-list-group__header {
  margin-top: 7px;
  padding-bottom: -5px;
}

::v-deep .v-list-group__items > .v-list-item {
  padding-left: 57px !important;
}

/* No way else as far as i know to change arrow append icon color collapsed */
::v-deep .v-icon {
  color: var(--v-drawer_color-base) !important;
}
</style>

<script>
export default {
  name: "MainMenu",
  data: () => ({
    drawer: false,
    items: [
      {
        action: "mdi-wallet",
        active: true,
        items: [{ title: "Demandes", route: "/" }],
        title: "Portefeuille",
      },
    ],
  }),
  created() {},
  mounted() {},
  computed: {},
  methods: {},
  watch: {},
};
</script>
