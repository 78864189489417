<template lang="pug">
div
  v-btn.secondary.button_color--text(v-scroll='onScroll' v-show='fab' fab fixed bottom right @click='toTop')
    v-icon keyboard_arrow_up
</template>

<script>
export default {
  name: "ScrollTop",
  data: () => ({
    fab: false,
  }),
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.scrollY || e.target.scrollTop || 0;
      this.fab = top > 700;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>
